import React, { useState,useEffect } from 'react';
import axios from 'axios';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError('New password and confirm password do not match');
            return;
        }

        const token = localStorage.getItem('authToken');
        
        if (!token) {
            setError('Authentication token not found');
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            const response = await axios.post('https://modernhouseapi.homes.modernhouse.homes/api/users/change_password', {
                currentPassword,
                newPassword,
                confirmPassword
            }, config);

            if (response.status === 200) {
                setSuccessMessage(response.data.msg);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setError('');
            } else {
                setError(response.data.msg || 'An error occurred');
            }
        } catch (error) {
            setError(error.response?.data?.msg || 'An error occurred');
            setSuccessMessage('');
        }
    }
        // Effect hook to clear success and error messages after some time
        useEffect(() => {
            if (successMessage || error) {
                const timer = setTimeout(() => {
                    setError("");
                    setSuccessMessage("");
                }, 2000);
                return () => clearTimeout(timer);
            }
        }, [successMessage, error]);

    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Dashboard :</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">Change Password</a></li>
                    </ol>
                </div>

                <div className="row">
                    <div className="col-xl-8">
                        <div className="card h-auto">
                            <div className="card-body">
                                <div className="profile-tab">
                                    <div className="custom-tab-1">
                                        <div className="tab-content">
                                            <div id="profile-settings" className="tab-pane fade active show">
                                                <div className="pt-3">
                                                    <div className="settings-form">
                                                        <h4 className="text-primary">Change Password</h4>
                                                        {error && <div className="alert alert-danger">{error}</div>}
                                                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="row mt-4">
                                                                <div className="mb-3 col-md-12">
                                                                    <label className="form-label">Current Password</label>
                                                                    <input
                                                                        type="password"
                                                                        placeholder="Current Password"
                                                                        className="form-control"
                                                                        value={currentPassword}
                                                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="mb-3 col-md-12">
                                                                    <label className="form-label">New Password</label>
                                                                    <input
                                                                        type="password"
                                                                        placeholder="New Password"
                                                                        className="form-control"
                                                                        value={newPassword}
                                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="mb-3 col-md-12">
                                                                    <label className="form-label">Confirm Password</label>
                                                                    <input
                                                                        type="password"
                                                                        placeholder="Confirm Password"
                                                                        className="form-control"
                                                                        value={confirmPassword}
                                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-primary" type="submit">Update Password</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>									
                                </div>
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
