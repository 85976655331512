import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link , useNavigate} from 'react-router-dom';
function PropertiesList() {
    const [properties, setProperties] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch properties from the API
        axios.get('https://modernhouseapi.homes.modernhouse.homes/api/properties')
            .then(response => {
                setProperties(response.data.properties);
            })
            .catch(error => {
                console.error('Error fetching properties:', error);
            });
    }, []);

    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Property:</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">Property List</a></li>
                    </ol>
                </div>

                <div className="my-3 row">
                    <div className="col-sm-6 align-self-center">
                        <p className="mb-0 d-sm-block d-none">
                            Showing 1-9 of {properties.length} Results
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="d-flex align-items-center me-2">
                                <label className="form-label w-100 me-2 mb-0">Sort by:</label>
                                <select className="default-select form-control wide">
                                    <option>Default</option>
                                    <option>For Rent</option>
                                    <option>For Sale</option>
                                </select>
                            </div>
                            <ul className="nav nav-pills style-1">
                                <li className="nav-item">
                                    <a href="#navpills-1" className="nav-link active" data-bs-toggle="tab" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid">
                                            <rect x="3" y="3" width="7" height="7"></rect>
                                            <rect x="14" y="3" width="7" height="7"></rect>
                                            <rect x="14" y="14" width="7" height="7"></rect>
                                            <rect x="3" y="14" width="7" height="7"></rect>
                                        </svg>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#navpills-2" className="nav-link" data-bs-toggle="tab" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list">
                                            <line x1="8" y1="6" x2="21" y2="6"></line>
                                            <line x1="8" y1="12" x2="21" y2="12"></line>
                                            <line x1="8" y1="18" x2="21" y2="18"></line>
                                            <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                            <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                            <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="tab-content">
                    <div id="navpills-1" className="tab-pane active">
					<div className="row">
            {properties.map(property => (
                <div key={property.id} className="item slide-box col-lg-4">
                    <div className="property-box">
                        <div className="property-thumbnail">
                            <Link to="/propertieslist" className="property-img">
                                <div className="listing-badges">
                                    <span className="featured">Featured</span>
                                </div>
                                <div className="price-ratings-box">
                                    <h4 className="price">
                                        ${property.property_price}<span>/mo</span>
                                    </h4>
                                </div>
                                <div className="property-overflow">
                                    <img className="d-block w-100"   src={`https://modernhouseapi.homes.modernhouse.homes/${property.media}`} 
 alt="property"/>
                                </div>
                            </Link>
                        </div>
                        <div className="detail">
                            <h1 className="title">
                                <Link to="/propertieslist">{property.property_type}</Link>
                            </h1>
                            <div className="location">
                                <Link to="/propertieslist">
                                    <i className="fa fa-map-marker"></i>{property.address}
                                </Link>
                            </div>
                            <ul className="facilities-list clearfix">
                                <li>
                                    <i className="flaticon-furniture"></i> {property.beds} Bedrooms
                                </li>
                                <li>
                                    <i className="flaticon-holidays"></i> {property.baths} Bathrooms
                                </li>
                                <li>
                                    <i className="flaticon-square"></i> Sq Ft: {property.area}
                                </li>
                                <li>
                                    <i className="flaticon-vehicle"></i> {property.max_rooms} Garage
                                </li>
                            </ul>
                        </div>
                        <div className="footer clearfix">
                      
                            <div className="text-center">
                            <button
    className='btn btn-primary'
    onClick={() => navigate('/checkout', { state: { property } })} // Pass the property data
>
    Buy Now
</button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertiesList;
