import React, { useState } from 'react';
import axios from 'axios';

function SendMessage() {

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user_id = localStorage.getItem('userId');
        const created_at = new Date().toISOString();
        const ticket_no = generateTicketNo();
        
        try {
            // First check if the user exists in the database
            const checkUserResponse = await axios.get('https://modernhouseapi.homes.modernhouse.homes/api/checkuser', {
                params: { email }
            });
    
            if (checkUserResponse.data.exists) {
                const to_id = checkUserResponse.data.user.id; 
                
                // Proceed to send the message
                const response = await axios.post('https://modernhouseapi.homes.modernhouse.homes/api/usermessage', {
                    subject,
                    message,
                    user_id,
                    email,
                    mails: email,
                    ticket_no,
                    status: 0,
                    created_at,
                    to_id // Include the 'to_id'
                });
    
                setSuccessMessage(response.data.msg);
                setErrors('');
            } else {
                setErrors('User with this email not found');
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Error during API call:', error.response ? error.response.data : error.message);
            setErrors('Error sending message');
            setSuccessMessage('');
        }
    };
    

    const generateTicketNo = () => {
        return 'tK' + Math.random().toString(36).substring(2, 10) + '_' + Math.floor(Math.random() * 10);
    };

    return (
        <>
            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                            <li className="breadcrumb-item active"><a href="javascript:void(0)">Support</a></li>
                        </ol>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Send Message</h4>
                                </div>
                                <div className="card-body">
                                    {successMessage && <div className="alert alert-success text-center">{successMessage}</div>}
                                    {errors && <div className="alert alert-danger text-center">{errors}</div>}
                                    <div className="basic-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label">Email</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Email" 
                                                        value={email} 
                                                        onChange={(e) => setEmail(e.target.value)} 
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label">Subject</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Subject" 
                                                        value={subject} 
                                                        onChange={(e) => setSubject(e.target.value)} 
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label">Message</label>
                                                    <textarea 
                                                        className="form-control" 
                                                        placeholder="Message" 
                                                        value={message} 
                                                        onChange={(e) => setMessage(e.target.value)} 
                                                        rows={5}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendMessage;
