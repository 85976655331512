import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

function Support() {
    const [supportMessages, setSupportMessages] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchSupportData = async () => {
            try {
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    setError('User ID not found in local storage.');
                    setSupportMessages([]); // Clear messages if userId is not found
                    return;
                }
        
                // Request support data from the backend
                const response = await axios.get('https://modernhouseapi.homes.modernhouse.homes/api/support', {
                    params: { userId } // Pass userId as a query parameter
                });
        
                if (response.data && response.data.supportMessages) {
                    // Filtered messages
                    const filteredMessages = response.data.supportMessages;
                    console.log('Filtered messages:', filteredMessages); // Debug output
                    setSupportMessages(filteredMessages);
                    setError(''); // Clear any previous errors
                } else {
                    console.error('Unexpected response format:', response.data);
                    setError('An unexpected error occurred while fetching support data.');
                    setSupportMessages([]); // Clear messages if response format is unexpected
                }
            } catch (error) {
                console.error('Error fetching support data:', error);
                setError('An error occurred while fetching support data.');
                setSupportMessages([]); // Clear messages if there was an error
            }
        };
        
        
        fetchSupportData();
    }, []);

    const getStatusTextAndColor = (status) => {
        switch (status) {
            case 1:
                return { text: 'Resolved', color: 'green' };
            case 0:
            default:
                return { text: 'Not resolved', color: 'red' };
        }
    };

    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">Support</a></li>
                    </ol>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-block">
                                <div className="row">
                                    <div className="col-6"><h4 className="card-title">Support</h4></div>
                                    <div className="col-6 text-end">
                                        <Link to='/create_support' className='btn btn-primary'>Send Message</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {error && <div className="alert alert-danger">{error}</div>}
                                <div className="table-responsive">
                                    <table className="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Subject</th>
                                                <th>Status</th>
                                                <th>View Chat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {supportMessages.map((message, index) => {
                                                const status = getStatusTextAndColor(message.status);
                                                return (
                                                    <tr key={message.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{moment(message.date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>{message.subject}</td>
                                                        <td style={{ color: status.color }}>{status.text}</td>
                                                        <td><Link to={`/create_support`} className='btn btn-primary'>View Chat</Link></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;
