import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'; // A library to format dates

const AllUsers = () => {
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://modernhouseapi.homes.modernhouse.homes/api/users');
                const filteredUsers = response.data.users.filter(user => user.role === 1); // Filter users by role 1
                setUsers(filteredUsers);
                setTotal(filteredUsers.length); // Update total count based on filtered users
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter users based on search query
    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">All Users</a></li>
                    </ol>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-block">
                                <div className="row">
                                    <div className="col-6">
                                        <h4 className="card-title">Users</h4>
                                    </div>
                                    <div className="col-6 text-end">
                                        <h5>Total Users: {total}</h5>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by username"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/* <th>Name</th> */}
                                                <th>Username</th>
                                                <th>Email</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredUsers.map((user, index) => (
                                                <tr key={user.id}>
                                                    <td>{index + 1}</td>
                                                    {/* <td>{user.name}</td> */}
                                                    <td>{user.username}</td>
                                                    <td>{user.email}</td>
                                                    <td>{moment(user.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllUsers;
