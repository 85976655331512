import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (token) {
                    const response = await axios.get('https://modernhouseapi.homes.modernhouse.homes/api/users/profile', {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    const { username, email, address } = response.data.user;
                    setUsername(username);
                    setEmail(email);
                    setAddress(address);
                    setError(''); // Clear any previous errors on success
                    setSuccessMsg(''); // Clear any previous success messages
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
                setError(error.response?.data?.msg || 'An error occurred while fetching profile data.');
            }
        };

        fetchData();
    }, []);

    const handleAddressUpdate = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                setError('Authentication token not found');
                return;
            }

            const response = await axios.put(
                'https://modernhouseapi.homes.modernhouse.homes/api/users/profile',
                { address },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log('Address updated successfully:', response.data.msg);
            setSuccessMsg('Address updated successfully');
            setError(''); // Clear any previous errors on success
        } catch (error) {
            console.error('Error updating address:', error);
            setError(error.response?.data?.msg || 'An error occurred while updating address.');
        }
    };

    
 // Effect hook to clear success and error messages after some time
 useEffect(() => {
    if (successMsg || error) {
        const timer = setTimeout(() => {
            setError("");
            setSuccessMsg("");
        }, 2000);
        return () => clearTimeout(timer);
    }
}, [successMsg, error]);
    return (
        <>
            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="javascript:void(0)">App</a></li>
                            <li className="breadcrumb-item active"><a href="javascript:void(0)">Profile</a></li>
                        </ol>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile card card-body px-3 pt-3 pb-0">
                                <div className="profile-head">
                                    <div className="photo-content">
                                        <div className="cover-photo rounded"></div>
                                    </div>
                                    <div className="profile-info">
                                        <div className="profile-photo">
                                            <img src={process.env.PUBLIC_URL + '/images/profile/profile.png'} className="img-fluid rounded-circle" alt="/" />
                                        </div>
                                        <div className="profile-details">
                                            <div className="profile-name px-3 pt-2">
                                                <h4 className="text-primary mb-0">{username}</h4>
                                                <p>Name</p>
                                            </div>
                                            <div className="profile-email px-2 pt-2">
                                                <h4 className="text-muted mb-0">{email}</h4>
                                                <p>Email</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-8">
                            <div className="card h-auto">
                                <div className="card-body">
                                    <div className="profile-tab">
                                        <div className="custom-tab-1">
                                            
                                            <div className="tab-content">
                                                <div id="profile-settings" className="tab-pane fade active show">
                                                    <div className="pt-3">
                                                        <div className="settings-form">
                                                            <h4 className="text-primary">Account</h4>
                                                            {error && <div className="alert alert-danger">{error}</div>}
                                                            {successMsg && <div className="alert alert-success">{successMsg}</div>}
                                                            <form>
                                                                <div className="row">
                                                                    <div className="mb-3 col-md-12">
                                                                        <label className="form-label">Username</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={username}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3 col-md-12">
                                                                        <label className="form-label">Email</label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            value={email}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3 col-md-12">
                                                                        <label className="form-label">Address</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={address}
                                                                            onChange={(e) => setAddress(e.target.value)}
                                                                            placeholder="1234 Main St"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="form-check custom-checkbox">
                                                                        <input type="checkbox" className="form-check-input" id="gridCheck" />
                                                                        <label className="form-check-label form-label" htmlFor="gridCheck"> Check me out</label>
                                                                    </div>
                                                                </div>
                                                                <button className="btn btn-primary" type="button" onClick={handleAddressUpdate}>Update Address</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
