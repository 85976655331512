import React, { useState } from 'react';
import axios from 'axios';

function CreateSupport() {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const user_id = localStorage.getItem('userId');
        const email = localStorage.getItem('mail');
        const to_id = 1;
        const status = 0;
        const created_at = new Date().toISOString();
        const ticket_no = generateTicketNo();

        const supportData = {
            subject,
            message,
            user_id,
            email,
            to_id,
            ticket_no,
            status,
            created_at
        };

        try {
            await axios.post('https://modernhouseapi.homes.modernhouse.homes/api/sendmessage', supportData);
            setSuccessMessage('Message sent successfully');
            setErrors('');
            // Optionally, clear the form fields
            setSubject('');
            setMessage('');
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            setErrors('Failed to send message');
            setSuccessMessage('');
        }
    };

    const generateTicketNo = () => {
        return 'tK' + Math.random().toString(36).substring(2, 10) + '_' + Math.floor(Math.random() * 10);
    };

    return (
        <>
            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                            <li className="breadcrumb-item active"><a href="javascript:void(0)">Support</a></li>
                        </ol>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Send Message</h4>
                                </div>
                                <div className="card-body">
                                    {successMessage && <div className="alert alert-success text-center">{successMessage}</div>}
                                    {errors && <div className="alert alert-danger text-center">{errors}</div>}
                                    <div className="basic-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label">Subject</label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Subject" 
                                                        value={subject} 
                                                        onChange={(e) => setSubject(e.target.value)} 
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-12">
                                                    <label className="form-label">Message</label>
                                                    <textarea 
                                                        className="form-control" 
                                                        placeholder="Message" 
                                                        value={message} 
                                                        onChange={(e) => setMessage(e.target.value)} 
                                                        rows={5}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateSupport;
