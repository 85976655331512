import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const property = location.state.property;

    // Retrieve user details from localStorage
    const name = localStorage.getItem('user');
    const email = localStorage.getItem('mail');

    useEffect(() => {
        // Debugging to check if the values are being retrieved correctly
        console.log('Retrieved name from localStorage:', name);
        console.log('Retrieved email from localStorage:', email);

        const script = document.createElement('script');
        script.src = "https://www.paypal.com/sdk/js?client-id=ATAwRgn-ckdDmPS7x8IC7zzxnpv9NJXLWXWHtZgXIOwWUnzrgtBQwm3rvwmtOJ4Xrajo-P0-Ewx-2GmK&currency=USD";
        script.onload = () => {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: property.property_price,
                                currency_code: 'USD'
                            },
                        }],
                    });
                },
                onApprove: async (data, actions) => {
                    const details = await actions.order.capture();
                    await saveDataToDatabase(details);
                    Swal.fire({
                        title: 'Payment Complete!',
                        text: 'Thank you for your purchase.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        navigate('/dashboard');
                    });
                },
                onError: (err) => {
                    console.error('PayPal Checkout onError', err);
                }
            }).render('#paypal-button-container');
        };
        script.onerror = () => {
            console.error('PayPal script failed to load.');
        };
        document.body.appendChild(script);
    }, [property, navigate]);

    const saveDataToDatabase = async (data) => {
        try {
            console.log('Sending data to the database', {
                orderId: data.id,
                name: name,
                email: email,
                propertyId: property.id,
                amount: property.property_price,
                currency: 'USD',
                status: data.status
            });
            await axios.post('https://modernhouseapi.homes.modernhouse.homes/api/save-property', {
                orderId: data.id,
                name: name,
                email: email,
                propertyId: property.id,
                amount: property.property_price,
                currency: 'USD',
                status: data.status
            });
        } catch (error) {
            console.error('Error saving data to database:', error);
        }
    };

    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Property:</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">Property List</a></li>
                    </ol>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h1>Checkout for {property.property_type}</h1>
                            </div>
                            <div className="card-body">
                                <div className="checkout">
                                    <div>
                                        <h4>Price: ${property.property_price}</h4>
                                        <div id="paypal-button-container"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Checkout;
