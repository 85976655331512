// Chat.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Chat() {
    const { chatId } = useParams();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`https://modernhouseapi.homes.modernhouse.homes/api/chat/${chatId}`);
                setMessages(response.data.messages);
            } catch (error) {
                console.error('Error fetching chat messages:', error);
            }
        };

        fetchMessages();
    }, [chatId]);

    const handleSendMessage = async () => {
        try {
            await axios.post('https://modernhouseapi.homes.modernhouse.homes/api/chat', {
                chatId,
                userId,
                toId: 1, // Adjust as needed
                message: newMessage
            });
            setNewMessage('');
            // Fetch messages again to include the new message
            const response = await axios.get(`https://modernhouseapi.homes.modernhouse.homes/api/chat/${chatId}`);
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="chat-container">
            <h2>Chat</h2>
            <div className="messages">
                {messages.map(msg => (
                    <div key={msg.id} className={`message ${msg.user_id === userId ? 'sent' : 'received'}`}>
                        <p>{msg.message}</p>
                        <small>{new Date(msg.created_at).toLocaleString()}</small>
                    </div>
                ))}
            </div>
            <div className="send-message">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message"
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
}

export default Chat;
