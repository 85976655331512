import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
    const [user, setUser] = useState({
        username: '', // Change email to username
        password: ''
    });
    const [errors, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { username, password } = user;
    const { login } = useAuth();
    const navigate = useNavigate();

    // Function to generate a random authentication token
    const generateAuthToken = () => {
        const tokenLength = 8;
        const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let token = '';
        for (let i = 0; i < tokenLength; i++) {
            token += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
        }
        return token;
    }

    // Function to handle input change
    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // Function to handle form submission
    const onSubmit = async (e) => {
        e.preventDefault();
        const authToken = generateAuthToken();
        
        try {
            const res = await axios.post('https://modernhouseapi.homes.modernhouse.homes/api/users/login', {
                ...user,
                token: authToken
            });
            setSuccessMessage(res.data.msg);

            localStorage.setItem('authToken', res.data.user.token);
            localStorage.setItem('userRole', res.data.user.role);
            localStorage.setItem('userId', res.data.user.id);
            localStorage.setItem('mail', res.data.user.email);
            localStorage.setItem('user', res.data.user.username);
            console.log(res.data.user.role);
            setError("");
            login(res.data.user);
            // Redirect to appropriate dashboard based on role
            if (res.data.user.role === 2) {
                navigate('/admindashboard');
            } else if (res.data.user.role === 1) {
                navigate('/dashboard');
            }
        } catch (err) {
            setError(err.response.data.msg);
            setSuccessMessage("");
        }
    };

    // Effect hook to clear success and error messages after some time
    useEffect(() => {
        if (successMessage || errors) {
            const timer = setTimeout(() => {
                setError("");
                setSuccessMessage("");
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, errors]);

    return (
        <>
            <div className="fix-wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="card mb-0 h-auto">
                                <div className="card-body">
                                    <div className="text-center mb-3">
                                        <Link to='/'><img className="logo-auth" src={process.env.PUBLIC_URL + '/images/logo-full.png'} alt="" /></Link>
                                    </div>

                                    <h4 className="text-center mb-4">Sign in to your account</h4>
                                    {successMessage && <div className="alert alert-success text-center">{successMessage}</div>}
                                    {errors && <div className="alert alert-danger text-center">{errors}</div>}
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group mb-4">
                                            <label className="form-label" htmlFor="username">Username</label>
                                            <input type="text" name="username" value={username} onChange={onChange} className="form-control"
                                                id="username" placeholder="Enter Username" required />
                                        </div>
                                        <div className="form-group mb-3 mb-sm-4">
                                            <label className="form-label">Password</label>
                                            <div className="position-relative">
                                                <input type="password" name="password" value={password} onChange={onChange} className="form-control"
                                                    id="dz-password" placeholder="Enter Password" required />
                                            </div>
                                        </div>
                                        <div className="form-row d-flex flex-wrap justify-content-between align-items-baseline mb-2">
                                            <div className="form-group mb-sm-4 mb-1">
                                                <div className="form-check custom-checkbox ms-1">
                                                    <input type="checkbox" className="form-check-input" id="rememberMeCheckbox" />
                                                    <label className="form-check-label" htmlFor="rememberMeCheckbox">Remember my preference</label>
                                                </div>
                                            </div>
                                            <div className="form-group ms-2">
                                                <Link to="/forgot_password">Forgot Password?</Link>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                        </div>
                                    </form>
                                    <div className="new-account mt-3">
                                        <p>Don't have an account? <Link className="text-primary" to="/register">Sign up</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;
