import React, { useState, useEffect } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import axios from 'axios';
const Register = () => {
  
  const [user, setUser] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    c_password: ''
  });
  const [errors, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const { name, username, email, password, c_password } = user;

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post('https://modernhouseapi.homes.modernhouse.homes/api/users/register', user);
      setSuccessMessage(res.data.msg);
      setError("");
      navigate('/login?registered=true');
    } catch (err) {
      setError(err.response.data.msg);
      setSuccessMessage("");
    }
  };


  useEffect(() => {
    if (successMessage || errors) {
        const timer = setTimeout(() => {
            setError("");
            setSuccessMessage("");
        }, 2000);
        return () => clearTimeout(timer);
    }
}, [successMessage, errors]);
  return (
    <>
      <div className="fix-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6">

              <div className="card mb-0 h-auto">


                <div className="card-body">
                  <div className="text-center mb-3">
                  <Link to='/'><img className="logo-auth" src={process.env.PUBLIC_URL + '/images/logo-full.png'} alt="" /></Link>
                  </div>
                  <h4 className="text-center mb-4">Sign up your account</h4> 
                            {successMessage && <div className="alert alert-success text-center">{successMessage}</div>}
                            {errors && <div className="alert alert-danger text-center">{errors}</div>}
                  <form onSubmit={onSubmit}>
                    <div className="form-group mb-4">
                      <label className="form-label" for="username">Enter Name</label>
                      <input type="text" className="form-control" id="fullname" placeholder="Enter Name" name="name" value={name} onChange={onChange} required />
                    </div>

                    <div className="form-group mb-4">
                      <label className="form-label" for="username">Username</label>
                      <input type="text" className="form-control" id="fullname" placeholder="Enter Name" name="username" value={username} onChange={onChange} required />
                    </div>


                    <div className="form-group mb-4">
                      <label className="form-label" for="email">Email</label>
                      <input type="email" className="form-control" id="email" placeholder="Enter Email" name="email" value={email} onChange={onChange} required />
                    </div>

                    <div className="form-group mb-sm-4 mb-3">
                      <label className="form-label">Password</label>
                      <div className="position-relative">
                        <input type="password" className="form-control" id="password" placeholder="Enter Password" name="password" value={password} onChange={onChange} required />
                      </div>
                    </div>

                    <div className="form-group mb-sm-4 mb-3">
                      <label className="form-label">Confirm Password</label>
                      <div className="position-relative">
                        <input type="password" name="c_password" value={c_password} onChange={onChange} required className="form-control" id="password" placeholder="Confirm Password" />
                      </div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-primary btn-block">Sign up</button>

                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>Already have an account? <Link className="text-primary" to="/login">Sign in</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Register;