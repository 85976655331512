import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

function AdminSupport() {
    const [supportMessages, setSupportMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [searchEmail, setSearchEmail] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchSupportData = async () => {
            try {
                const response = await axios.get('https://modernhouseapi.homes.modernhouse.homes/api/support');
                const userId = localStorage.getItem('userId'); // Assuming user_id is stored in localStorage

                // Filter messages to show only unresolved (status 0) and exclude messages from the current user
                const filtered = response.data.supportMessages.filter(message => {
                    return  message.user_id !== parseInt(userId);
                });

                setSupportMessages(filtered);
                setFilteredMessages(filtered); // Initialize filteredMessages with all messages
                setError('');
            } catch (error) {
                console.error('Error fetching support data:', error);
                setError('An error occurred while fetching support data.');
            }
        };

        fetchSupportData();
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchEmail(query);
        const filtered = supportMessages.filter(message =>
            message.email.toLowerCase().includes(query)
        );
        setFilteredMessages(filtered);
    };

    const getStatusTextAndColor = (status) => {
        switch (status) {
            case 1:
                return { text: 'Resolved', color: 'green' };
            case 0:
            default:
                return { text: 'Not resolved', color: 'red' };
        }
    };

    function wordLimiter(str, limit) {
        // Split the string into an array of words
        const words = str.split(' ');
    
        // If the number of words is less than or equal to the limit, return the original string
        if (words.length <= limit) {
            return str;
        }
    
        // Join the first 'limit' number of words and add ellipsis (if needed)
        return words.slice(0, limit).join(' ') + (words.length > limit ? '...' : '');
    }
    

    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">Support</a></li>
                    </ol>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-block">
                                <div className="row">
                                    <div className="col-6"><h4 className="card-title">Support</h4></div>
                                    <div className="col-6 text-end">
                                        <Link to='/sendmessages' className='btn btn-primary'>Send Message</Link>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by email"
                                            value={searchEmail}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {error && <div className="alert alert-danger">{error}</div>}
                                <div className="table-responsive">
                                    <table className="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Email</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Status</th>
                                                <th>View Chat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredMessages.map((message, index) => {
                                                const status = getStatusTextAndColor(message.status);
                                                return (
                                                    <tr key={message.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{moment(message.date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>{message.email}</td>
                                                        <td>{message.subject}</td>
                                                        <td style={{width:'30px'}}>{wordLimiter(message.message, 2)}</td>
                                                        <td style={{ color: status.color }}>{status.text}</td>
                                                        <td><Link to={`/sendmessages`} className='btn btn-primary'>View Chat</Link></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSupport;
