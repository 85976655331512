import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useNavigate,Link } from 'react-router-dom'; // Import useNavigate

function AllProperties() {
    const [properties, setProperties] = useState([]);
    const [total, setTotal] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await axios.get('https://modernhouseapi.homes.modernhouse.homes/api/properties');
                setProperties(response.data.properties);
                setTotal(response.data.properties.length);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };
        fetchProperties();
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredProperties = properties.filter(property =>
        property.address.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this property?');
        if (confirmDelete) {
            try {
                await axios.delete(`https://modernhouseapi.homes.modernhouse.homes/api/properties/${id}`);
                setProperties(properties.filter(property => property.id !== id));
                setTotal(total - 1);
                alert('Property deleted successfully');
            } catch (error) {
                console.error('Error deleting property:', error);
                alert('Failed to delete property');
            }
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit-property/${id}`); // Navigate to edit page
    };

    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Dashboard</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">All Properties</a></li>
                    </ol>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-block">
                                <div className="row">
                                    <div className="col-6">
                                        <h4 className="card-title">Properties</h4>
                                    </div>
                                    <div className="col-6 text-end">
                                        <h5>Total Properties: {total}</h5>
                                        <Link to='/addproperty' className='btn btn-primary mt-4'>Add Property</Link>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by address"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Price</th>
                                                <th>Status</th>
                                                <th>Date Added</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredProperties.map((property, index) => (
                                                <tr key={property.id}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <img
                                                            className="d-block cust_property_width"
                                                            src={`https://modernhouseapi.homes.modernhouse.homes/${property.media}`}
                                                            alt="property"
                                                        />
                                                    </td>
                                                    <td>{property.property_type}</td>
                                                    <td>{property.address}</td>
                                                    <td>${property.property_price}</td>
                                                    <td>{property.property_status}</td>
                                                    <td>{moment(property.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                    <td>
                                                        <button className="btn btn-warning btn-sm btn_side_span" onClick={() => handleEdit(property.id)}>
                                                            Edit
                                                        </button>
                                                        <button className="btn btn-danger btn-sm" onClick={() => handleDelete(property.id)}>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllProperties;
