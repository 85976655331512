import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/MemberPanel/Header';
import Index from './Website/Index';
import Dashboard from './Components/Pages/Dashboard';
import AddProperties from './Components/Pages/Properties/AddProperties';
import Login from './Components/Pages/Login';
import Register from './Components/Pages/Register';
import ForgotPassword from './Components/Pages/ForgotPassword';
import Profile from './Components/Pages/Profile';
import Properties from './Components/Pages/Properties';
import PropertiesList from './Components/Pages/PropertiesList';
import ChangePassword from './Components/Pages/ChangePassword';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import Support from './Components/Pages/Support';
import CreateSupport from './Components/Pages/CreateSupport';
import Chat from './Components/Pages/Chat';
import AdminDashboard from './Admin/Pages/AdminDashbaord';
import AllUsers from './Admin/Pages/AllUsers';
import AdminSupport from './Admin/Pages/AdminSupport';
import SendMessage from './Admin/Pages/SendMessage';
import AddProperty from './Admin/Pages/AddProperty';
import AllProperties from './Admin/Pages/AllProperties';
import EditProperty from './Admin/Pages/EditProperty';
import Checkout from './Components/Pages/Checkout';

function App() {   
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgot_password' element={<ForgotPassword />} />

          {/* Root path to render the Index component */}
          <Route path='/' element={<Index />} />

          {/* Layout route for other paths */}
          <Route path='/' element={<Header />}>
            <Route path='dashboard' element={<ProtectedRoute element={<Dashboard />} roles={[1]} />} />
            <Route path='add_property' element={<ProtectedRoute element={<AddProperties />} roles={[1]} />} />
            <Route path='property' element={<ProtectedRoute element={<Properties />} roles={[1]} />} />
            <Route path='profile' element={<ProtectedRoute element={<Profile />} roles={[1]} />} />
            <Route path='propertieslist' element={<ProtectedRoute element={<PropertiesList />} roles={[1]} />} />
            <Route path='changepassword' element={<ProtectedRoute element={<ChangePassword />} roles={[1]} />} />
            <Route path='support' element={<ProtectedRoute element={<Support />} roles={[1]} />} />
            <Route path='create_support' element={<ProtectedRoute element={<CreateSupport />} roles={[1]} />} />
            <Route path='chat/:chatId' element={<ProtectedRoute element={<Chat />} roles={[1]} />} />
            <Route path='admindashboard' element={<ProtectedRoute element={<AdminDashboard />} roles={[2]} />} />
            <Route path='users' element={<ProtectedRoute element={<AllUsers />} roles={[2]} />} />
            <Route path='messages' element={<ProtectedRoute element={<AdminSupport />} roles={[2]} />} />
            <Route path='sendmessages' element={<ProtectedRoute element={<SendMessage />} roles={[2]} />} />
            <Route path='addproperty' element={<ProtectedRoute element={<AddProperty />} roles={[2]} />} />
            <Route path='allproperty' element={<ProtectedRoute element={<AllProperties />} roles={[2]} />} />
            <Route path='edit-property/:id' element={<ProtectedRoute element={<EditProperty />} roles={[2]} />} />
            <Route path='checkout' element={<ProtectedRoute element={<Checkout />} roles={[1]} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
