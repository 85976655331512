import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function PropertyCards() {
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        axios.get('https://modernhouseapi.homes.modernhouse.homes/api/properties')
            .then(response => {
                setProperties(response.data.properties);
            })
            .catch(error => {
                console.error('Error fetching properties:', error);
            });
    }, []);

    return (
        <div className="row">
            {properties.map(property => (
                <div key={property.id} className="item slide-box col-lg-4">
                    <div className="property-box">
                        <div className="property-thumbnail">
                            <Link to="/login" className="property-img">
                                <div className="listing-badges">
                                    <span className="featured">Featured</span>
                                </div>
                                <div className="price-ratings-box">
                                    <h4 className="price">
                                        ${property.property_price}<span>/mo</span>
                                    </h4>
                                </div>
                                <div className="property-overflow">
                                    <img className="d-block w-100"   src={`https://modernhouseapi.homes.modernhouse.homes/${property.media}`} 
 alt="property"/>
                                </div>
                            </Link>
                        </div>
                        <div className="detail">
                            <h1 className="title">
                                <Link to="/login">{property.property_type}</Link>
                            </h1>
                            <div className="location">
                                <Link to="/login">
                                    <i className="fa fa-map-marker"></i>{property.address}
                                </Link>
                            </div>
                            <ul className="facilities-list clearfix">
                                <li>
                                    <i className="flaticon-furniture"></i> {property.beds} Bedrooms
                                </li>
                                <li>
                                    <i className="flaticon-holidays"></i> {property.baths} Bathrooms
                                </li>
                                <li>
                                    <i className="flaticon-square"></i> Sq Ft: {property.area}
                                </li>
                                <li>
                                    <i className="flaticon-vehicle"></i> {property.max_rooms} Garage
                                </li>
                            </ul>
                        </div>
                        <div className="footer clearfix">
                      
                      <div className="text-center">
                          <Link to='/login' className='btn btn-primary'>Buy Now</Link>
                      </div>
                  </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PropertyCards;
