import { Link } from "react-router-dom"
import React, { useState } from 'react';
import PopertyCards from "./PopertyCards";
function Index(){
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarVisible(!isSidebarVisible);
    };
     // Get userRole from localStorage
     const userRole = localStorage.getItem('userRole');

     // Determine the display text and link based on userRole
     let navItem;
     if (userRole === null) {
         // If userRole is not in localStorage, show "Login"
         navItem = (
             <li className="nav-item">
                 <Link to='/login' className="nav-link h-icon"><i className="fa fa-plus"></i> Login</Link>
             </li>
         );
     } else if (userRole === '1') {
         // If userRole is '1', show "Test"
         navItem = (
             <li className="nav-item">
                 <Link to='/dashboard' className="nav-link h-icon"><i className="fa fa-plus"></i> Dashboard</Link>
             </li>
         );
     } else if (userRole === '2') {
         // If userRole is '2', show "Admin"
         navItem = (
             <li className="nav-item">
                 <Link to='/admindashboard' className="nav-link h-icon"><i className="fa fa-plus"></i>Dashboard</Link>
             </li>
         );
     }
    return(
        <>
        
   
<div class="top-header-3 d-none d-lg-block" id="top-header-4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-3">
                <div class="top-logo">
                    <Link to='/'><img src={process.env.PUBLIC_URL + '/images/logo-full.png'} alt="logo"/></Link>
                </div>
            </div>
            <div class="col-lg-9 col-md-9">
                <div class="top-header-inner">
                    <div class="top-contact-item">
                        <i class="bi bi-geo-alt"></i>
                        <div class="content">
                            <p>Our Location</p>
                            <Link to='/'target="blank">25/7 Barden, London</Link>
                        </div>
                    </div>
                    <div class="top-contact-item">
                        <i class="bi bi-envelope-open"></i>
                        <div class="content">
                            <p>Online Support</p>
                            <Link to='/'>test@gmail.com</Link>
                        </div>
                    </div>
                    <div class="top-contact-item tci2">
                        <i class="bi bi-telephone-inbound"></i>
                        <div class="content">
                            <p>Free Contact</p>
                            <Link to='/'>+91 9876543210</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<header class="main-header sticky-header header-fixed main-header-5" id="main-header-6">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <Link to='/' class="navbar-brand logos mr-auto">
                <img src={process.env.PUBLIC_URL + '/img/logo_white.png'} alt="logo" class="logo-photo"/>
            </Link>
            <button className="navbar-toggler" id="drawer" type="button" onClick={toggleSidebar}>
        <span className="fa fa-bars"></span>
      </button>
            <div class="navbar-collapse collapse w-100" id="navbar">
                <ul class="navbar-nav w-100 justify-content-start">
                    <li class="nav-item dropdown active">
                        <Link to='/' class="nav-link" aria-expanded="false">
                            Home
                        </Link>
                    </li>
                    <li class="nav-item dropdown">
                    <a href="#chosse" class="nav-link">
                            Why Choose Us
                        </a>
                       
                    </li>
                    <li class="nav-item dropdown">
                    <Link to='/' class="nav-link">
                            Properties
                        </Link>
                      
                    </li>
                    <li class="nav-item dropdown">
                    <Link to='/register' class="nav-link">
                            Register
                        </Link>
                      
                    </li>
                    
                    
                </ul>

                <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                {navItem}
                </ul>
            </div>
        </nav>
    </div>
</header>
<nav id="sidebar" className={`nav-sidebar ${isSidebarVisible ? 'mCS_destroyed active' : ''}`}>
        <div id="dismiss" onClick={toggleSidebar}>
          <i className="fa fa-close"></i>
        </div>
        <div className="sidebar-inner">
          <div className="sidebar-logo">
            <img src={process.env.PUBLIC_URL + '/img/logos/logo.png'} alt="sidebarlogo" />
          </div>
          <div className="sidebar-navigation">
            <ul className="menu-list">
              <li>
                <Link to="/" className="active pt0">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/">
                Why Choose Us
                </Link>
              </li>
              <li>
                <Link to="/">
                  Properties
                </Link>
              </li>
              <li>
                <Link to="/register">
                  Register
                </Link>
              </li>
              <li>
                <Link to="/login">
                  Login
                </Link>
              </li>
            </ul>
          </div>
          <div className="get-in-touch">
            <h3 className="heading">Get in Touch</h3>
            <div className="get-in-touch-box d-flex">
            <i class="fa-solid fa-phone"></i>
              <div className="detail">
                <Link to='/login'>+91 9876543210</Link>
              </div>
            </div>
            <div className="get-in-touch-box d-flex">
            <i class="fa-solid fa-envelope"></i>
              <div className="detail">
              <Link to='/login'>info@test.com</Link>
              </div>
            </div>
            <div className="get-in-touch-box d-flex mb-0">
            <i class="fa-solid fa-earth-americas"></i>
              <div className="detail">
              <Link to='/login'>info@test.com</Link>
              </div>
            </div>
          </div>
          <div className="get-social">
            <h3 className="heading">Get Social</h3>
            <a href="#" className="facebook-bg">
            <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a href="#" className="twitter-bg">
            <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="#" className="google-bg">
            <i class="fa-brands fa-google"></i>
            </a>
            <a href="#" className="linkedin-bg">
            <i class="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
      </nav>
<div class="banner" id="banner">
    <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item item-bg active">
                <img class="d-block w-100 h-100" src={process.env.PUBLIC_URL + '/img/banner/img-2.jpg'} alt="banner"/>
                <div class="carousel-caption banner-slider-inner d-flex h-100 text-start">
                    <div class="carousel-content container align-self-center">
                        <div class="banner-info2">
                            <div class="text-l">
                                <h3>Find Your Amazing Modern House</h3>
                                <p>Find new & featured property located in your local city.</p>
                                <Link to='/register' class="btn-2">Get Started Now</Link>
                                <Link to='login' class="btn-1"><span>Login Now</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item item-bg">
                <img class="d-block w-100 h-100" src={process.env.PUBLIC_URL + '/img/banner/img-3.jpg'} alt="banner"/>
                <div class="carousel-caption banner-slider-inner d-flex h-100 text-start">
                    <div class="carousel-content container align-self-center">
                        <div class="t-left banner-info2">
                            <div class="text-r">
                                <h3>Discover Modern Villa</h3>
                                <p>Find new & featured property located in your local city.</p>
                                <Link to='/register' class="btn-2">Get Started Now</Link>
                                <Link to='login' class="btn-1"><span>Login Now</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item item-bg">
                <img class="d-block w-100 h-100" src={process.env.PUBLIC_URL + '/img/banner/img-1.jpg'} alt="banner"/>
                <div class="carousel-caption banner-slider-inner d-flex h-100 text-start">
                    <div class="carousel-content container align-self-center">
                        <div class="t-left banner-info2">
                            <div class="text-center">
                                <h3>Find Your Dreem Property</h3>
                                <p>Find new & featured property located in your local city.</p>
                                <Link to='/register' class="btn-2">Get Started Now</Link>
                                <Link to='login' class="btn-1"><span>Login Now</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</div>

<div class="featured-properties content-area-9 comon-slick" style={{paddingTop: '100px'}}>
    <div class="container">
        <div class="main-title">
            <p>Best Rent Properties</p>
            <h1>Featured Properties</h1>
        </div>
        <div class="slick row comon-slick-inner csi2 wow fadeInUp delay-04s">
          <PopertyCards/>
            
        
        
        
        
        </div>
        <div class="text-center">
            <Link to='/login' class="button btn-3">
                Browse More Properties
            </Link>
        </div>
    </div>
</div>
<div class="services-3 bg-grea-3" id="choose">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 align-self-center wow fadeInLeft delay-04s">
                <div class="text">
                    <div class="main-title mt3">
                        <p>Our Expertise</p>
                        <h1>Why Choose Us</h1>
                    </div>
                    <p class="px">We offer personalized service, extensive market knowledge, and a proven track record in real estate. Our dedicated team provides expert guidance, ensuring a seamless buying or selling experience with the best deals and professional support. Let us turn your real estate dreams into reality.</p>
                </div>
            </div>
            <div class="col-lg-8 wow fadeInRight delay-04s">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="service-info-2">
                            <div class="number">1</div>
                            <div class="icon">
                            <i class="fa-solid fa-building"></i>
                            </div>
                            <div class="service-info-2-ditels">
                                <h3><Link to='/'>Apartments</Link></h3>
                                <p>Discover top-quality apartments with expert guidance, personalized service, and the best value. Your perfect home awaits.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="service-info-2">
                            <div class="number">2</div>
                            <div class="icon">
                            <i class="fa-solid fa-wifi"></i>
                            </div>
                            <div class="service-info-2-ditels">
                                <h3><Link to='/'>Houses</Link></h3>
                                <p>Explore expertly selected houses with personalized service, market expertise, and great value. Find your ideal home with us.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="service-info-2">
                            <div class="number">3</div>
                            <div class="icon">
                            <i class="fa-solid fa-warehouse"></i>
                            </div>
                            <div class="service-info-2-ditels">
                                <h3><Link to='/'>Garages</Link></h3>
                                <p>Get quality garages with excellent value, expert advice, and tailored solutions. Secure your ideal space efficiently and affordably.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="service-info-2">
                            <div class="number">4</div>
                            <div class="icon">
                            <i class="fa-solid fa-coins"></i>
                            </div>
                            <div class="service-info-2-ditels">
                                <h3><Link to='/'>Commercial</Link></h3>
                                <p>Discover prime commercial spaces with expert insights, tailored solutions, and exceptional value. Optimize your business potential with us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="popular-places content-area-8">
    <div class="container">
        <div class="main-title">
            <p>Find Your City</p>
            <h1>Most Popular Places</h1>
        </div>
        <div class="row wow">
            <div class="col-lg-7 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-sm-6 wow fadeInLeft delay-04s col-pad">
                        <div class="popular-places-box">
                            <div class="popular-places-overflow">
                                <div class="popular-places-photo">
                                    <img class="img-fluid" src={process.env.PUBLIC_URL + '/img/popular-places/popular-places-4.jpg'} alt="popular-places"/>
                                </div>
                            </div>
                            <div class="listings_no">47 Properties</div>
                            <div class="ling-section">
                                <h3>
                                    <a href="#">United States</a>
                                </h3>
                                <p>Friendly neighborhood</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 wow fadeInLeft delay-04s col-pad">
                        <div class="popular-places-box">
                            <div class="popular-places-overflow">
                                <div class="popular-places-photo">
                                    <img class="img-fluid" src={process.env.PUBLIC_URL + '/img/popular-places/popular-places-1.jpg'} alt="popular-places"/>
                                </div>
                            </div>
                            <div class="listings_no">24 Properties</div>
                            <div class="ling-section">
                                <h3>
                                    <a href="#">London</a>
                                </h3>
                                <p>The big apple</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 wow fadeInUp delay-04s col-pad">
                        <div class="popular-places-box">
                            <div class="popular-places-overflow">
                                <div class="popular-places-photo">
                                    <img class="img-fluid" src={process.env.PUBLIC_URL + '/img/popular-places/popular-places-2.jpg'} alt="popular-places"/>
                                </div>
                            </div>
                            <div class="listings_no">64 Properties</div>
                            <div class="ling-section">
                                <h3>
                                    <a href="#">Rome City</a>
                                </h3>
                                <p>The Best City</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 wow fadeInRight delay-04s col-pad">
                <div class="popular-places-box">
                    <div class="popular-places-overflow">
                        <div class="popular-places-photo">
                            <img class="img-fluid big" src={process.env.PUBLIC_URL + '/img/popular-places/popular-places-3.jpg'} alt="popular-places"/>
                        </div>
                    </div>
                    <div class="listings_no">76 Properties</div>
                    <div class="ling-section">
                        <h3>
                            <a href="#">Australia</a>
                        </h3>
                        <p>Best place to live!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="counters-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 align-self-center wow fadeInLeft delay-04s">
                <div class="heading">
                    <p>Our Experience</p>
                    <h1>More than 10 Years of Experience</h1>
                </div>
            </div>
            <div class="col-lg-7 wow fadeInRight delay-04s">
                <div class="infos clearfix">
                    <div class="counter-box b-button b-right d-flex">
                        <div class="icon">
                            <i class="flaticon-tag"></i>
                        </div>
                        <div class="detail">
                            <h1 class="counter Starting">967</h1>
                            <p>Properties Sold</p>
                        </div>
                    </div>
                    <div class="counter-box b-button d-flex">
                        <div class="icon">
                            <i class="flaticon-business"></i>
                        </div>
                        <div class="detail">
                            <h1 class="counter">1276</h1>
                            <p>Workers Employed</p>
                        </div>
                    </div>
                    <div class="counter-box b-right d-flex">
                        <div class="icon">
                            <i class="flaticon-people"></i>
                        </div>
                        <div class="detail">
                            <h1 class="counter">396</h1>
                            <p>Awards Wining</p>
                        </div>
                    </div>
                    <div class="counter-box d-flex">
                        <div class="icon">
                            <i class="flaticon-people-1"></i>
                        </div>
                        <div class="detail">
                            <h1 class="counter">177</h1>
                            <p>Satisfied Customers</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="our-team-2 content-area">
    <div class="container">
        <div class="main-title">
            <p>Our Professionals</p>
            <h1>Meet Our Skilled Team</h1>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft delay-04s">
                <div class="team-1">
                    <div class="team-inner">
                        <div class="team-overflow">
                            <div class="team-photo">
                                <div class="team-img">
                                    <a href="#">
                                        <img src={process.env.PUBLIC_URL + '/img/avatar/avatar-7.jpg'} alt="agent-2" class="img-fluid w-100"/>
                                    </a>
                                </div>
                                <ul class="social-list clearfix">
                                    <li><a href="#" class="facebook-bg"><i class="fa-brands fa-facebook"></i></a></li>
                                    <li><a href="#" class="twitter-bg"><i class="fa-brands fa-twitter"></i></a></li>
                                    <li><a href="#" class="google-bg"><i class="fa-brands fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-details">
                            <h5><Link to='/'>Martin Smith</Link></h5>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft delay-04s">
                <div class="team-1">
                    <div class="team-inner">
                        <div class="team-overflow">
                            <div class="team-photo">
                                <div class="team-img">
                                    <a href="#">
                                        <img src={process.env.PUBLIC_URL + '/img/avatar/avatar-6.jpg'} alt="agent-2" class="img-fluid w-100"/>
                                    </a>
                                </div>
                                <ul class="social-list clearfix">
                                    <li><a href="#" class="facebook-bg"><i class="fa-brands fa-facebook"></i></a></li>
                                    <li><a href="#" class="twitter-bg"><i class="fa-brands fa-twitter"></i></a></li>
                                    <li><a href="#" class="google-bg"><i class="fa-brands fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-details">
                            <h5><Link to='/'>John Pitarshon</Link></h5>
                            <p>Creative Director</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInRight delay-04s">
                <div class="team-1">
                    <div class="team-inner">
                        <div class="team-overflow">
                            <div class="team-photo">
                                <div class="team-img">
                                    <a href="#">
                                        <img src={process.env.PUBLIC_URL + '/img/avatar/avatar-8.jpg'} alt="agent-2" class="img-fluid w-100"/>
                                    </a>
                                </div>
                                <ul class="social-list clearfix">
                                    <li><a href="#" class="facebook-bg"><i class="fa-brands fa-facebook"></i></a></li>
                                    <li><a href="#" class="twitter-bg"><i class="fa-brands fa-twitter"></i></a></li>
                                    <li><a href="#" class="google-bg"><i class="fa-brands fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-details">
                            <h5><Link to='/'>Maria Blank</Link></h5>
                            <p>Office Manager</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInRight delay-04s">
                <div class="team-1">
                    <div class="team-inner">
                        <div class="team-overflow">
                            <div class="team-photo">
                                <div class="team-img">
                                    <a href="#">
                                        <img src={process.env.PUBLIC_URL + '/img/avatar/avatar-5.jpg'} alt="agent-2" class="img-fluid w-100"/>
                                    </a>
                                </div>
                                <ul class="social-list clearfix">
                                    <li><a href="#" class="facebook-bg"><i class="fa-brands fa-facebook"></i></a></li>
                                    <li><a href="#" class="twitter-bg"><i class="fa-brands fa-twitter"></i></a></li>
                                    <li><a href="#" class="google-bg"><i class="fa-brands fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="team-details">
                            <h5><a href="#">Karen Paran</a></h5>
                            <p>Support Manager</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="testimonial-4">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 bg-image"></div>
            <div class="col-lg-6 col-md-12 col-pad bg-grea-3">
                <div id="carouselExampleFade2" class="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleFade2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleFade2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleFade2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="testimonial-info">
                                <div class="main-title">
                                    <p>Testimonials</p>
                                    <h1>Customer Reviews</h1>
                                </div>
                                <p class="text">Buying our new apartment with this team was a breeze. Their expertise and attention to detail made the process smooth and enjoyable. Highly recommend!"</p>
                                <div class="avatar">
                                    <img src={process.env.PUBLIC_URL + '/img/avatar/avatar-1.jpg'} alt="testimonial-user"/>
                                </div>
                                <h4>Jane D.</h4>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="testimonial-info">
                                <div class="main-title">
                                    <p>Testimonials</p>
                                    <h1>Customer Reviews</h1>
                                </div>
                                <p class="text">Exceptional service! The agents were knowledgeable, responsive, and really understood our needs. We found the perfect commercial space quickly and at a great price.</p>
                                <div class="avatar">
                                    <img src={process.env.PUBLIC_URL + '/img/avatar/avatar-3.jpg'} alt="testimonial-user"/>
                                </div>
                                <h4>Carolyn Stone</h4>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="testimonial-info">
                                <div class="main-title">
                                    <p>Testimonials</p>
                                    <h1>Customer Reviews</h1>
                                </div>
                                <p class="text">Selling our house was stress-free thanks to their professional approach. They provided excellent marketing and negotiation skills, resulting in a quick sale above our expectations.</p>
                                <div class="avatar">
                                    <img src={process.env.PUBLIC_URL + '/img/avatar/avatar-4.jpg'} alt="testimonial-user"/>
                                </div>
                                <h4>Michelle Nelson</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="blog content-area-5">
    <div class="container">
        <div class="main-title">
            <p>Our Blog</p>
            <h1>Latest news</h1>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInLeft delay-04s">
                <div class="row blog-2 g-0">
                    <div class="col-lg-5 col-md-5">
                        <div class="photo">
                            <img src={process.env.PUBLIC_URL + '/img/blog/blog-7.jpg'} alt="blog-2" class="img-fluid"/>
                            <div class="date-box">
                                07 Feb
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7 align-self-center">
                        <div class="detail">
                            <h3>
                                <Link to='/'>Selling Your Real House</Link>
                            </h3>
                           
                            <p>Selling your home can be a daunting process, but staying informed is key. Our blog offers the latest news and expert advice on real estate trends, market insights, and effective selling strategies. From preparing your home for sale to navigating negotiations, we cover everything you need to know to maximize your property's value and ensure a smooth transaction.</p>
                            <Link to='/' class="read-more">Read more</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 wow fadeInRight delay-04s">
                <div class="blog-4 d-flex">
                    <div class="date">
                        <h1>14</h1>
                        <h5>June</h5>
                    </div>
                    <div class="detail">
                        <h3>
                            <Link to='/'>Find Your Dream Villa</Link>
                        </h3>
                        <div class="post-meta">
                            <span><a href="#"><i class="fa fa-user"></i> Sobuz Ali</a></span>
                            <span><a href="#"><i class="fa fa-clock-o"></i> 3 Comment</a></span>
                        </div>
                    </div>
                </div>
                <div class="blog-4 d-flex">
                    <div class="date">
                        <h1>08</h1>
                        <h5>April</h5>
                    </div>
                    <div class="detail">
                        <h3>
                            <Link to='/'>Your Real Apartment</Link>
                        </h3>
                        <div class="post-meta">
                            <span><a href="#"><i class="fa fa-user"></i> Abbash</a></span>
                            <span><a href="#"><i class="fa fa-clock-o"></i> 245 Comment</a></span>
                        </div>
                    </div>
                </div>
                <div class="blog-4 d-flex">
                    <div class="date">
                        <h1>19</h1>
                        <h5>March</h5>
                    </div>
                    <div class="detail">
                        <h3>
                            <Link to='/'>Buying a Best House</Link>
                        </h3>
                        <div class="post-meta">
                            <span><a href="#"><i class="fa fa-user"></i> Tonzim</a></span>
                            <span><a href="#"><i class="fa fa-clock-o"></i> 8 Comment</a></span>
                        </div>
                    </div>
                </div>
                <div class="blog-4 d-flex mb-0">
                    <div class="date">
                        <h1>26</h1>
                        <h5>March</h5>
                    </div>
                    <div class="detail">
                        <h3>
                            <Link to='/'>Selling Your House</Link>
                        </h3>
                        <div class="post-meta">
                            <span><a href="#"><i class="fa fa-user"></i> Roy Shah</a></span>
                            <span><a href="#"><i class="fa fa-clock-o"></i> 7 Comment</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-1">
    <div class="container footer-inner">
        <div class="row">
            <div className="col-lg-12 text-center">
            <img src={process.env.PUBLIC_URL + '/images/logo-white.png'} alt="logo" className="footer_logo"/>
            <p className="mt-4">Find new & featured property located in your local city.</p>
            <ul class="social-list clearfix"><li><a href="#" class="facebook-bg"><i class="fa-brands fa-facebook"></i></a></li><li><a href="#" class="twitter-bg"><i class="fa-brands fa-twitter"></i></a></li><li><a href="#" class="google-bg"><i class="fa-brands fa-google-plus-g"></i></a></li><li><a href="#" class="rss-bg"><i class="fa-solid fa-rss"></i></a></li><li><a href="#" class="linkedin-bg"><i class="fa-brands fa-linkedin"></i></a></li></ul>
            </div>
        </div>
    
    </div>
</footer>
<div class="sub-footer">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <p class="copy"><a href="#">ModernHouse
</a> © 2024 All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>

<div id="full-page-search">
    <button type="button" class="close">×</button>
    <form>
        <input type="search" value="" placeholder="type keyword(s) here"/>
        <button type="submit" class="btn btn-sm button-theme">Search</button>
    </form>
</div>

        
        </>
    )
}
export default Index