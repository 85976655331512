import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AddProperty() {
    const [formData, setFormData] = useState({
        property_type: '',
        property_status: 'For Rent',
        property_price: '',
        max_rooms: 1,
        beds: 1,
        baths: 1,
        area: '',
        premiere: 'Blue Sky',
        description: '',
        address: '',
        zip_code: '',
        media: null
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            media: e.target.files[0]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        axios.post('https://modernhouseapi.homes.modernhouse.homes/api/property', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log(response.data);
                setSuccessMessage('Property added successfully!');
                setErrors('');
            })
            .catch(error => {
                console.error('There was an error adding the property!', error);
                setSuccessMessage('');
                setErrors('There was an error adding the property!');
            });
    };

    useEffect(() => {
        if (successMessage || errors) {
            const timer = setTimeout(() => {
                setErrors("");
                setSuccessMessage("");
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, errors]);

    return (
        <>
            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="javascript:void(0)">Property</a></li>
                            <li className="breadcrumb-item active"><a href="javascript:void(0)">Add Property</a></li>
                        </ol>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Add Property</h4>
                                </div>
                                <div className="card-body">
                                    {successMessage && <div className="alert alert-success text-center">{successMessage}</div>}
                                    {errors && <div className="alert alert-danger text-center">{errors}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="mb-3 col-lg-4 col-md-6">
                                                <label className="form-label">Property Type</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="office,villa,apartment"
                                                    name="property_type"
                                                    value={formData.property_type}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-lg-4 col-md-6">
                                                <label className="form-label">Property Status</label>
                                                <select
                                                    className="default-select form-control wide"
                                                    name="property_status"
                                                    value={formData.property_status}
                                                    onChange={handleChange}
                                                >
                                                    <option>For Rent</option>
                                                    <option>For Sale</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-lg-4 col-md-6">
                                                <label className="form-label">Property Price</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="$2800"
                                                    name="property_price"
                                                    value={formData.property_price}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3 col-lg-4 col-md-6">
                                                <label className="form-label">Max Rooms</label>
                                                <select
                                                    className="default-select form-control wide"
                                                    name="max_rooms"
                                                    value={formData.max_rooms}
                                                    onChange={handleChange}
                                                >
                                                    {[1, 2, 3, 4, 5, 6].map(num => (
                                                                                                               <option key={num} value={num}>{num}</option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Beds</label>
                                                                                                        <select
                                                                                                            className="default-select form-control wide"
                                                                                                            name="beds"
                                                                                                            value={formData.beds}
                                                                                                            onChange={handleChange}
                                                                                                        >
                                                                                                            {[1, 2, 3, 4, 5, 6].map(num => (
                                                                                                                <option key={num} value={num}>{num}</option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Baths</label>
                                                                                                        <select
                                                                                                            className="default-select form-control wide"
                                                                                                            name="baths"
                                                                                                            value={formData.baths}
                                                                                                            onChange={handleChange}
                                                                                                        >
                                                                                                            {[1, 2, 3, 4, 5, 6].map(num => (
                                                                                                                <option key={num} value={num}>{num}</option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Area (sq ft)</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            placeholder="1500"
                                                                                                            name="area"
                                                                                                            value={formData.area}
                                                                                                            onChange={handleChange}
                                                                                                            required
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Premiere</label>
                                                                                                        <select
                                                                                                            className="default-select form-control wide"
                                                                                                            name="premiere"
                                                                                                            value={formData.premiere}
                                                                                                            onChange={handleChange}
                                                                                                        >
                                                                                                            <option>Blue Sky</option>
                                                                                                            <option>Sunset Villa</option>
                                                                                                            <option>Ocean View</option>
                                                                                                            <option>Mountain Retreat</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Description</label>
                                                                                                        <textarea
                                                                                                            className="form-control"
                                                                                                            placeholder="Description about the property"
                                                                                                            name="description"
                                                                                                            value={formData.description}
                                                                                                            onChange={handleChange}
                                                                                                            required
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Address</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            placeholder="1234 Main St"
                                                                                                            name="address"
                                                                                                            value={formData.address}
                                                                                                            onChange={handleChange}
                                                                                                            required
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Zip Code</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            placeholder="12345"
                                                                                                            name="zip_code"
                                                                                                            value={formData.zip_code}
                                                                                                            onChange={handleChange}
                                                                                                            required
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-lg-4 col-md-6">
                                                                                                        <label className="form-label">Media</label>
                                                                                                        <input
                                                                                                            type="file"
                                                                                                            className="form-control"
                                                                                                            name="media"
                                                                                                            onChange={handleFileChange}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <button type="submit" className="btn btn-primary">Add Property</button>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        }
                                                        
                                                        export default AddProperty;
                                                        
